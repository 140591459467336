
// Primary Imports
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import {
  TransitionFade,
} from '/src/components/animators';

import { METADATA } from '/src/globals';
import AnimateComponents from '/src/page-sections/demo/animation/AnimateComponents';
import TransitionComponents from '/src/page-sections/demo/animation/TransitionComponents';
import ScrollComponents from '/src/page-sections/demo/animation/ScrollComponents';
import DemoNavigation from '/src/collections/DemoNavigation';
import PageWrapper from '../../components/PageWrapper';

//////////////////////////

const propTypes = {
  theme: PropTypes.object,
};

const Animation = (props) => {

  return ( <>

    <Helmet>
      <title>Animator Components{METADATA.titleBarPostfix}</title>
      <meta name="description" content="This page is about..." />
    </Helmet>

    <PageWrapper>
      
      <TransitionFade duration={1000}>
        <Typography variant="h1">
          Animation
        </Typography>
      </TransitionFade>
      
      <AnimateComponents/>
      <TransitionComponents/>
      <ScrollComponents/>
      
      <DemoNavigation/>

    </PageWrapper>
        
  </> )

}

Animation.propTypes = propTypes;
export default Animation;
