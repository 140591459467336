import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

//////////////////////////

const useStyles = makeStyles( theme => ({
  
  scrollingAnimationWrapper: {
    padding: '200px 100px',
  },

  cube: {
    width: '100px',
    height: '100px',
    backgroundColor: theme.palette.primary.main,
    margin: [0, '!important'],
    position: 'relative',
    left: 'calc(50% - 50px)',
  },

}));

//////////////////////////

const ScrollComponents = props => {

  const theme = useTheme();
  const classes = useStyles(props);

  //////

  return ( <>


    <Typography variant="h2">
      Scroll Components
    </Typography>

    
    <Typography variant="h3">
      Animate with Scroll
    </Typography>
   
    <div className={clsx(classes.scrollingAnimationWrapper)}>
      <div id="trigger" />

      <Controller>

        <Scene triggerElement="#trigger" duration={300}>
          {progress => (
            <Tween
              to={{
                left: '0px',
                rotation: -360,
                width: '200px',
                height: '200px',
              }}
              ease="Strong.easeOut"
              totalProgress={progress}
              paused
            >
              <div className={clsx(classes.cube)} />
            </Tween>
          )}
        </Scene>

        <Scene triggerElement="#trigger" duration={600} pin={false}>
          {progress => (
            <Tween
              from={{
                css: {
                  left: '0px',
                  rotation: -360,
                  width: '200px',
                  height: '200px',
                },
                ease: 'Strong.easeOut',
              }}
              to={{
                css: {
                  left: '300px',
                  rotation: -180,
                  width: '50px',
                  height: '50px',
                },
                ease: 'Strong.easeOut',
              }}
              totalProgress={progress}
              paused
            >
              <div className={clsx(classes.cube)} />
            </Tween>
          )}
        </Scene>

      </Controller>
      
    </div>

  </> );
};

export default ScrollComponents;
