import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  TransitionZoomIn,
  TransitionZoomOut,
  TransitionZoomInOut,
  TransitionZoomOutIn,
  TransitionFade,
} from '/src/components/animators';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';

//////////////////////////

const TransitionComponents = props => {

  // Object and method to access and store controls for animator components
  const animControls = {};
  const setAnimatorControls = (id, controls) => {
    animControls[id] = controls;
  }

  //////

  return ( <>


    <Typography variant="h2">
      Transition Components
    </Typography>

    <Typography variant="body1">
      By default, these do animate immediately on mount.<br/>
      Calling "enter" when a component has already entered or "exit" if already exited, nothing will happen.
    </Typography>

    
    <Grid
      container
      justify = "center"
      alignItems = "center"
    >

      <Grid item xs={12} md={4}>
        <TransitionFade setControls={setAnimatorControls} id="fade1">
          <Typography variant="h3">
            Fade
          </Typography>
        </TransitionFade>
      </Grid>

      <Grid item xs={12} md={8}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["fade1"].enter()}
        >
          Enter
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["fade1"].exit()}
        >
          Exit
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["fade1"].toggle()}
        >
          Toggle
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["fade1"].glimpse()}
        >
          Glimpse
        </Button>
      </Grid>

    </Grid>



    <Grid
      container
      justify = "center"
      alignItems = "center"
      >

      <Grid item xs={12} md={4}>
        <TransitionZoomIn setControls={setAnimatorControls} id="zoomIn">
          <Typography variant="h3">
            Zoom In
          </Typography>
        </TransitionZoomIn>
      </Grid>

      <Grid item xs={12} md={8}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomIn"].enter()}
          >
          Enter
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomIn"].exit()}
          >
          Exit
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomIn"].toggle()}
          >
          Toggle
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomIn"].glimpse()}
          >
          Glimpse
        </Button>
      </Grid>

    </Grid>


    <Grid
      container
      justify = "center"
      alignItems = "center"
      >

      <Grid item xs={12} md={4}>
        <TransitionZoomOut setControls={setAnimatorControls} id="zoomOut">
          <Typography variant="h3">
            Zoom Out
          </Typography>
        </TransitionZoomOut>
      </Grid>

      <Grid item xs={12} md={8}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomOut"].enter()}
          >
          Enter
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomOut"].exit()}
          >
          Exit
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomOut"].toggle()}
          >
          Toggle
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomOut"].glimpse()}
          >
          Glimpse
        </Button>
      </Grid>

    </Grid>


    <Grid
      container
      justify = "center"
      alignItems = "center"      
      >

      <Grid item xs={12} md={4}>
        <TransitionZoomInOut setControls={setAnimatorControls} id="zoomInOut" glimpse>
          <Typography variant="h3">
            Zoom In/Out
          </Typography>
        </TransitionZoomInOut>
      </Grid>

      <Grid item xs={12} md={8}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomInOut"].enter()}
          >
          Enter
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomInOut"].exit()}
          >
          Exit
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomInOut"].toggle()}
          >
          Toggle
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomInOut"].glimpse()}
          >
          Glimpse
        </Button>
      </Grid>

    </Grid>


    <Grid
      container
      justify = "center"
      alignItems = "center"
      >

      <Grid item xs={12} md={4}>
        <TransitionZoomOutIn setControls={setAnimatorControls} id="zoomOutIn">
          <Typography variant="h3">
            Zoom Out/In
          </Typography>
        </TransitionZoomOutIn>
      </Grid>

      <Grid item xs={12} md={8}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomOutIn"].enter()}
          >
          Enter
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomOutIn"].exit()}
          >
          Exit
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomOutIn"].toggle()}
          >
          Toggle
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animControls["zoomOutIn"].glimpse()}
          >
          Glimpse
        </Button>
      </Grid>

    </Grid>
      

  </> );
};

TransitionComponents.propTypes = {
  theme: PropTypes.object,
};

export default TransitionComponents;
