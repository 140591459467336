import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  AnimateShake,
  AnimateSpin,
} from '/src/components/animators';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';

//////////////////////////

const AnimateComponents = props => {

  // Object and method to access and store controls for animator components
  const animatorControls = {};
  const setAnimatorControls = (id, controls) => {
    animatorControls[id] = controls;
  }

  //////

  return ( <>


    <Typography variant="h2">
      Animate Components
    </Typography>
    <Typography variant="body1">
      By default, these don't animate on mount.<br/>
    </Typography>
    

    <Grid container justify="center" spacing={2}>

      <Grid item xs={12} md={4}>
        <AnimateShake setControls={setAnimatorControls} id="shake1">
          <Typography variant="h3">
            Shake effect
          </Typography>
        </AnimateShake>
      </Grid>

      <Grid item xs={12} md={8}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animatorControls["shake1"].short()}
        >
          Short
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animatorControls["shake1"].medium()}
        >
          Medium
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animatorControls["shake1"].long()}
        >
          Long
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animatorControls["shake1"].on()}
        >
          On
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animatorControls["shake1"].off()}
        >
          Off
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animatorControls["shake1"].toggle()}
        >
          Toggle
        </Button>
      </Grid>

    </Grid>


    <Grid container>

      <Grid item xs={12} md={4}>
        <AnimateSpin setControls={setAnimatorControls} id="spin1">
          <Typography variant="h3">
            Spin effect
          </Typography>
        </AnimateSpin>
      </Grid>

      <Grid item xs={12} md={8}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animatorControls["spin1"].short()}
        >
          Short
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animatorControls["spin1"].medium()}
        >
          Medium
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animatorControls["spin1"].long()}
        >
          Long
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animatorControls["spin1"].on()}
        >
          On
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animatorControls["spin1"].off()}
        >
          Off
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick = {() => animatorControls["spin1"].toggle()}
        >
          Toggle
        </Button>
      </Grid>

    </Grid>
      

  </> );
};

export default AnimateComponents;
